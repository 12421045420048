.property-search {
    .search-form {
        padding: 75px 0 60px;
        label {
            display: block;
            margin: 8px 0;
            text-transform: uppercase;
            span {
                display: block;
                margin-bottom: 5px;
            }
            &.dropdown {
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    right: 15px;
                    top: 42px;
                    background-image: url(/images/arrow.svg);
                    background-size: 15px 15px;
                    background-position: center center !important;
                    transform: rotate(-90deg);
                    pointer-events: none;
                }
            }
        }
        input, select {
            width: 100%;
            border: none;
            border-radius: 10px;
            padding: 8px 15px;
            color: $grey;
            appearance: none;
            &:focus {
                outline: none;
            }
            &::-ms-expand {
                display: none;
            }
        }
        select {
            cursor: pointer;
        }
        button {
            position: relative;
            height: 42px;
            margin-top: 36px;
            width: 75px;
            background: url(/images/search.svg) center center no-repeat #56605d;
            border-radius: 10px;
            @media screen and (max-width: 1000px) {
                margin-top: 15px;
            }
        }    
        .nav {
            width: 100%;
            border-bottom: 0;
            margin: 37px 0 25px;
            li {
                flex-basis: 50%;
                margin-bottom: 0;
                &:first-child a {
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                }
                &:last-child a {
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                }
            }
            a {
                padding: 7px 15px;
                border: none;
                border-radius: 0;
                background: #FFF;
                color: $grey;
                text-align: center;
                text-transform: uppercase;
                &.active {
                    background: lighten($grey, 10%);
                    color: #FFF;
                    font-weight: 700;
                }
            }
        }
        .sale-rent-wrapper {
            transition: opacity .2s ease;
            &.active {
                opacity: .5;
                pointer-events: none;
            }
        }
        @media screen and (max-width: 1000px) {
            padding: 40px 0 25px;
            h1 {
                @include font-size(20);
            }  
            .nav {
                margin: 15px 0;
            }
        }
    }
    .search-results > .wrapper > h2 {
        padding-top: 20px;
        @media screen and (max-width: 1000px) {
            margin-bottom: 15px;    
        }
    }
    .search-results__controls {
        margin-top: -55px;
        & > div > .row > div:first-child {
            text-align: right;
            @media screen and (max-width: 1000px) {
                text-align: left;
            }
        }
        .search-results__controls--showing {
            line-height: 33px;
        }
        label {
            margin: 0;
            &.dropdown:after {
                top: 11px;
                right: 10px;
                width: 12px;
                height: 12px;
                background-size: 12px 12px;
            }
        }
        select {
            border: 1px solid $grey;
            padding: 5px 15px;
            @include font-size(9);
        }
        .nav-tabs {
            border: none;
            float: right;
            a {
                padding: 5px 0 5px 15px;
                height: 33px;
                border: none;
                svg * {
                    fill: $grey;
                }
                &.active svg * {
                    fill: $red;
                }
            }
        }
        @media screen and (max-width: 1000px) {
            margin-top: 0;    
        }
    } 
    .search-results__market-type {
        margin: 25px 0 50px;
        li {
            flex-basis: 25%;
            a {
                border: 1px solid $red;
                border-right: none;
                text-align: center;                
                color: $grey;
                transition: background .2s ease;
                padding: 8px;
                text-transform: uppercase;
                &:hover {
                    background: lighten($light-grey, 7%);
                }
                &.active {
                    background: $light-grey;
                    font-weight: bold;
                    color: $red;
                }
                @media screen and (max-width: 800px) {
                    height: 70px;
                    line-height: 110%;
                    vertical-align: middle;
                }
            }
            &.disabled a {
                opacity: .5;
                cursor: not-allowed;
            }
            &:first-child a {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            &:last-child a {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                border-right: 1px solid $red;
            }
        }
        @media screen and (max-width: 1000px) {
            display: block;
            li {
                a {
                    height: auto;
                    border-right: 1px solid $red;
                    border-bottom: none;
                }
                &:first-child a {
                    border-bottom-left-radius: 0;
                    border-top-right-radius:  10px;
                }
                &:last-child a {
                    border-bottom: 1px solid $red;
                    border-bottom-left-radius: 10px;
                    border-top-right-radius:  0;
                }
            }
        }
    }
    .search-results__results {
        .scheme-properties {
            background: $light_grey;
            .scheme-properties-container {
                padding: 0 25px 15px;
                h4 {
                    padding-top: 5px;
                    font-size: 1.2rem;
                    font-weight: 400;
                    a {
                        text-decoration: none;
                        color: $red;
                        transition: 0.5s;
                        &:hover {
                            text-decoration: underline;
                            color: darken($red, 7%);
                        }
                    }
                }
                table {
                    width: 100%;
                    background: $light-grey;
                    td, th {
                        padding: .55rem;
                    }
                    tbody tr {
                        td {
                            background: #fff;
                        }
                        &:hover td {
                            background: darken(#fff, 7%);
                        }
                    }
                    .arrow-button {
                        background: none;
                        border: none;
                        width: 50px;
                    }
                }
            }
        }
        .property-search__results--table {
            table {
                width: 100%;
                tbody tr:not(.scheme-properties) {
                        &:hover td {
                            background: $light-grey;
                        }
                }
                thead th {
                    border-top: 1px solid $grey;
                    border-bottom: 2px solid $grey;
                    background: $red;
                    color: #FFF;
                    text-transform: uppercase;
                }
                tbody td {
                    border-bottom: 1px solid $grey;
                    cursor: pointer;
                    transition: background .2s ease;
                    .arrow-button {
                        @include font-size(10);
                        padding: 0 10px 0 0;
                        width: 140px;
                        background: $light_grey;
                        border: solid 1px $light_grey;
                        img {
                            top: 6px;
                            right: 10px;
                            height: 12px;
                        }
                    }
                }
                @media screen and (max-width: 1000px) {
                    thead th, tbody td {
                        @include font-size(8);
                        padding: 8px 5px; 
                    }    
                }
            }
            .scheme-properties {
                display: none;
                .scheme-properties-container {
                    display: none;
                }
            }
        }
        &.map {
            #results-map {
                height: 700px;
                background: #EEE;
            }
            .infoBox {
                position: relative;
                width: 320px !important;
                background: #FFF;
                border-bottom-left-radius: 18px;
                border-bottom-right-radius: 18px;
                box-shadow: 2px 2px 4px #AAA;
                & > img {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
                div {
                    img {
                        max-width: 100% !important;
                        margin-top: -17px;
                    }
                    h3, h4, h5 {
                        @include font-size(10);
                        color: $red;
                        margin: 0 25px;
                    }
                    h3 {
                        margin-top: 15px;
                    }
                    h4 {
                        margin-bottom: 10px;
                        font-weight: 400;
                    }
                    h5 {
                        margin-bottom: 10px;
                        color: $grey;
                    }
                    a {
                        text-decoration: none;
                    }
                }
            }
        }
        article {
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid $grey;
            .scheme-properties {
                .scheme-properties-container {
                    h4 {
                        padding-top: 15px;
                    }
                }
            }
            table {
                width: 100%;
                .arrow-button {
                    padding: 0 !important;
                    img {
                        top: -4px !important;
                    }
                }
                tbody tr:not(.scheme-properties) {
                        &:hover td {
                            background: $light-grey;
                            // .arrow-button {
                            //     border: solid 1px #000;
                            // }
                        }
                }
                thead th {
                    border-top: 1px solid $grey;
                    border-bottom: 2px solid $grey;
                    background: $red;
                    color: #FFF;
                    text-transform: uppercase;
                }
                tbody td {
                    border-bottom: 1px solid $grey;
                    cursor: pointer;
                    transition: background .2s ease;
                    .arrow-button {
                        @include font-size(10);
                        padding: 0 10px 0 0;
                        width: 140px;
                        background: $light_grey;
                        border: solid 1px $light_grey;
                        img {
                            top: 6px;
                            right: 10px;
                            height: 12px;
                        }
                    }
                }
                @media screen and (max-width: 1000px) {
                    thead th, tbody td {
                        @include font-size(8);
                        padding: 8px 5px; 
                    }    
                }
            }
            .search-results__results--image {
                height: 200px;
                background-position: center center;
                background-size: cover;
            }
            a {
                text-decoration: none !important;
            }
            h3, h4 {
                margin: 0;
                color: $red;
                @include font-size(14);
            }
            h4 {
                font-weight: 400;
                margin-bottom: 15px;
                color: $grey;
            }
            p {
                @include font-size(9);
                color: $grey;
            }
            ul li {
                margin-bottom: 8px;
                span, a {
                    display: block;
                    position: relative;
                    background: lighten($light-grey, 7%);
                    border-radius: 8px;
                    padding: 10px 40px 10px 20px;
                    color: $grey;
                    transition: background .2s ease;                    
                    svg {
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        width: 15px;
                    }
                }
                a:hover {
                    text-decoration: none;
                    background: lighten($light-grey, 4%);
                }
                &:last-child a {
                    background: $grey;
                    color: #FFF;
                    svg * {
                        fill: #FFF;    
                    }
                }
            }
            @media screen and (max-width: 800px) {
                h3 {
                    margin-top: 25px;
                }    
                h4 {
                    margin-bottom: 25px;
                }
            }
        }
    }
    .search-results__pagination {
        margin-top: 25px;
        text-align: center;
        li {
            display: inline-block;
            margin: 0 3px;
        }
        a {
            display: block;
            padding-top: 1px;
            width: 25px;
            height: 25px;
            color: $grey;
            border-radius: 50%;
            background: transparent;
            text-decoration: none;
            &.active {
                background: $red !important;
                color: #FFF;
            }
            &:hover {
                background: #FFF;
            }
        }
}
}