html, body {
    width: 100%;
    height: 100%;
}

body {
    padding-top: 64px;
    max-width: 1800px;
    margin: 0 auto;
    overflow-x: hidden;
}

img {
    max-width: 100%;
}

p:last-of-type {
    margin-bottom: 0;
}

.wrapper {
    max-width: 1080px;
    padding: 0 20px;
    margin: 0 auto;
    @media screen and (max-width: 1200px) {
        padding: 0 40px;
    }
    @media screen and (max-width: 1000px) {
        padding: 0 25px;
    }
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 64px;
    padding: 15px 10px;
    background: #FFF;
    .wrapper {
        max-width: 1300px;
    }
    ul {   
        float: left;
        list-style: none;
        border-right: 1px solid $red;
        li {
            display: inline-block;
            position: relative;
            float: left;
            border-left: 1px solid $red;
            width: 170px;
            padding: 0 3px;
            ul {
                display: none;
                position: absolute;
                left: 5px;
                width: 155px;
                padding-top: 15px;
                background: #FFF;
                li {
                    width: 155px;
                    border-left: none;
                    padding: 0 5px 5px 5px;
                    a {
                        padding: 10px 5px !important; 
                        
                        &:hover {
                            background: lighten($grey, 65%) !important;
                        }
                    }
                    &:last-child a {
                        background-image: none !important;
                    }
                }
            }            
            &:hover ul {
                display: block;
            }
            @media screen and (max-width: 1200px) {
                width: 130px;
                ul {
                    &, li { 
                        width: 120px;
                    }
                }
            }
        }
        a {
            display: block;
            padding: 7px 5px;
            color: $grey;
            text-decoration: none;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: -1px;
            @include font-size(9);
            &.active {
                background: lighten($grey, 10%);
                color: #FFF;
            }
        }
        &:first-of-type {
            border-right: none;
        }
        &:last-of-type {
            float: right;
            li {
                &:first-child {
                    border-left: none;
                }
                &:last-child a {
                    padding: 7px 25px 5px 5px;
                    background-image: url(/images/search_grey.svg);
                    background-position: 94% 8px;
                    background-repeat: no-repeat;
                    &.active {
                        background-image: url("/images/search.svg");
                    }
                    @media screen and (max-width: 1200px) {
                        background: none;
                        padding: 5px;
                    }
                }                
            }
        }        
    }
    .logo {
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 500;
        transform: translateX(-50%);
        transition: all .25s linear;
        width: 190px;
        
    }
    .hamburger {
        position: absolute;
        top: 10px;
        right: 25px;
        @media screen and (min-width: 1000px) {
            display: none;
        }
        .line {
            width: 25px;
            height: 2px;
            background-color: #555759;
            display: block;
            margin: 10px auto;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            &:nth-child(2) {
                width: 15px;
                margin-right: 0;
            }
        }
        &:hover{
            cursor: pointer;
        }
        &.is-active .line {
            &:nth-child(2){
                opacity: 0;
            }
            &:nth-child(1){
                -webkit-transform: translateY(10px) rotate(45deg);
                -ms-transform: translateY(10px) rotate(45deg);
                -o-transform: translateY(10px) rotate(45deg);
                transform: translateY(10px) rotate(45deg);
            }
            &:nth-child(3){
                -webkit-transform: translateY(-14px) rotate(-45deg);
                -ms-transform: translateY(-14px) rotate(-45deg);
                -o-transform: translateY(-14px) rotate(-45deg);
                transform: translateY(-14px) rotate(-45deg);
            }
        }        
    }
    &.scrolling {
        .logo {
            height: 64px;
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 15px 0;    
    }
    @media screen and (max-width: 1000px) {
        .logo {
            height: 64px;
        }
        nav {
            display: none;
            height: 100vh;
            margin-top: 49px;
            padding-top: 20px;
            padding-bottom: 25px;
            border-top: 1px solid #EEE;
            background: #FFF;
            .wrapper {
                padding: 0;
            }
        }
        ul {
            display: block;
            float: none !important;
            border: none !important;
            li {
                display: block;
                float: none;
                width: auto;
                border: none !important;
                padding: 0;
                ul {
                    display: block;
                    position: static;
                    width: auto;
                    padding: 0 0 10px 0;
                    li {
                        width: auto;
                        padding: 0;
                        a {
                            padding: 5px 0 !important;
                            @include font-size(14);
                            &:hover {
                                background: none !important;
                            }
                        }
                    }
                }
            }
            a {
                @include font-size(16);
                padding: 8px 0;
            }            
        }
    }
}


.panel {
    position: relative;
    padding: 75px 0;
    h1 {
        @include font-size(28);
        text-transform: uppercase;
    }
    h2 {
        border-top: 1px solid $grey;
        padding-top: 10px;
        margin-bottom: 30px;
        @include font-size(14);
        color: $red;
        text-transform: uppercase;
    }
    &.grey {
        background: $light_grey;
    }
    &.light-grey {
        background: $light_grey;
    }
    &.red {
        background: $red;
        color: #FFF;
    }
    @media screen and (max-width: 1000px) {
        padding: 40px 0;    
    }
}

.popup {
    display: none;
    position: fixed;
    width: 100%;
    z-index: 3000;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 90px 0;
    background: #FFF;
    .wrapper {
        position: relative;
        .biography {
            background: $light_grey;
            padding: 25px 35px;
            border-radius: 20px;
            strong {
                display: block;
                margin-bottom: 15px;
            }
            p {
                margin: 0 0 15px;
                line-height: 130%;
                @include font-size(9);
                &:last-of-type {
                    margin: 0;
                }
            }
        }
        .close {
            position: absolute;
            top: -55px;
            right: 0;
            color: $red;
            opacity: 1;
        }
    }
}

// avoids modal layout shifting on open due to scrollbar
.modal-open {
    overflow: inherit;
    overflow-y:scroll;
    padding-right: 0px !important;
    .modal {
        padding-right: 0px !important;
    }
}

.overlay {
    display: none;
    position: fixed;
    z-index: 2500;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,.7);
}

.arrow-button {
    position: relative;
    display: block;
    width: 200px;
    border-radius: 25px;
    padding: 8px 35px 8px 15px;
    background: #FFF;
    color: $grey;  
    text-align: center;
    font-weight: 400;
    vertical-align: middle;
    text-decoration: none;
    @include font-size(12);
    img {
        position: absolute;
        top: 14px;
        right: 16px;
        height: 16px;
        transform: rotate(180deg);
    }
    &:hover {
        color: $grey;
        text-decoration: none;
    }
    &.back {
        width: 250px;
        padding: 8px 15px 8px 35px;
        img {
            right: auto;
            left: 16px;
            transform: rotate(0deg);
        }
    }
    &.dark {
        color: $grey!important;
        font-size: 14px!important;
        img {
            top: 8px!important;
        }
    }
    &.inverse {
        background: $grey;
        color: #FFF;
    }
}

hr {
    border-top-color: $grey;
    margin-bottom: 0;
}

.anchor-link {
    display: block;
    padding-top: 75px;
    margin-top: -75px;
}

.footer {
    position: relative;
    background: $grey;
    color: #FFF;
    padding: 50px 0;
    h4, p, li {
        @include font-size(8);
    }
    h4 {
        @include font-size(9);
        text-transform: uppercase;
        &.cap {
            text-transform: capitalize;
        }
    }
    h4 + h4 {
        margin-top: 20px;
    }
    ul {
        margin: 0;
    }
    a {
        color: #FFF;
    }
    svg {
        margin-top: 0;
        margin-bottom: 7px;
    }
    .row:last-of-type {
        margin-top: 25px;
        strong {
            @include font-size(12); 
            text-transform: uppercase;
        }
        p {
            // margin-top: 8px;
            text-align: right;
            a {
                display: inline-block;
                text-decoration: underline;
                margin-left: 20px;
            }
            &.credit {
                text-align: center;
                a {
                    margin: 0;
                }
            }
        }
    }
    @media screen and (max-width: 1000px) {
        padding: 40px 0;
        text-align: center;
        h4, p, li {
            @include font-size(10);
        }
        p {
            margin-bottom: 0;
        }
        h4 {
            margin-top: 25px;            
        }
        .row {
            div[class*=col]:first-child h4 {
                margin-top: 0;
            }
            &:last-of-type p {
                margin-bottom: 0;
                text-align: center;
            }
        }
    }
}

/* bxslider overwrites */
.bx-wrapper {
    margin: 0 auto;
    box-shadow: none;
    border: none;
    background: none;
    .bx-controls-direction {
        z-index: 999;
    }
    .bx-viewport {
        left: 0;
        box-shadow: none;
        border: none;
    }
    .bx-prev, .bx-next {
        left: 20px;
        background-image: url(/images/arrow_white.svg);
        background-size: 32px 32px;
        background-position: center center !important;
    }
    .bx-next {
        left: auto;
        right: 20px;
        transform: rotate(180deg);
        overflow: hidden;
    }
    .bx-pager {
        max-width: 1040px;
        left: 50%;
        bottom: 50px;
        z-index: 800;
        transform: translateX(-50%);
        text-align: left;
        &.bx-default-pager {
            a {
                width: 12px;
                height: 12px;
                border: 3px solid #FFF;
                background: #FFF;
                border-radius: 50%;
                margin: 0 20px 0 0;
                &.active {
                    background: $red;
                }
            }
        }
        @media screen and (max-width: 1200px) {
            width: calc(100% - 160px);    
        }
        @media screen and (max-width: 1000px) {
            width: calc(100% - 130px);   
            bottom: 15px;    
        }
    }
}