main.home {
    padding-top: 47vw;
    @media screen and (min-width: 1800px) {
        padding-top: 860px;
    }
}

.hero.home {
    position: fixed;
    top: 64px;
    width: 100%;
    max-width: 1800px;
    height: 47vw;
    max-height: 860px;
    .home-carousel {
        visibility: hidden;
    }
    .bx-wrapper, .bx-viewport {
        height: 47vw !important;
        max-height: 860px !important;
        img {
            width: 100% !important;
        }
        .slide {
            position: relative;            
            a {
                display: block;
                position: absolute;
                z-index: 50;
                top: 25px;
                right: 25px;
                bottom: 25px;
                left: 25px;
                transition: background .2s ease;
                &:hover {
                    background: rgba(255,255,255,.2);    
                    h2 {
                        opacity: 1;
                    }
                }
            }
            h1 {
                position: absolute;
                z-index: 45;
                left: 120px;
                bottom: 50%;
                width: 100%;
                transform: translateY(50%);            
                max-width: 630px;
                line-height: 90%;
                @include font-size(64);
                color: #FFF;
                text-transform: uppercase;
                @media screen and (max-width: 1000px) {
                    @include font-size(20);
                    left: 60px;
                    width: calc(100% - 120px);
                }
            }
            h2 {
                position: absolute;
                right: 100px;
                top: 80px;
                width: 150px;
                border-bottom: 5px solid #FFF;
                padding-bottom: 5px;
                font-weight: 400;
                @include font-size(11);
                color: #FFF;
                text-transform: uppercase;
                opacity: 0;
                transition: opacity .2s ease;
                @media screen and (max-width: 1000px) {
                    display: none;   
                }
            }
        }
        .bx-prev {
            left: 45px;
            @media screen and (max-width: 1000px) {
                left: 15px;
            }
        }
        .bx-next {
            right: 45px;
            @media screen and (max-width: 1000px) {
                right: 15px;
            }
        }
    }
    .wrapper {        
        form {
            position: relative;
            max-width: 380px;
            padding-right: 50px;
            margin-bottom: 80px;
            input {
                width: 100%;
                height: 50px;
                padding: 8px 25px;
                border: none;
                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;
                outline: none;
                @include font-size(16);
            }
            button {
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                height: 50px;
                border: none;
                background: $red;
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
            }
        }
        
        @media screen and (max-width: 1200px) {
            width: calc(100% - 80px);    
        }
        @media screen and (max-width: 1000px) {
            h1 {
                @include font-size(30);
            }    
        }
    }
}

.our-properties {
    background: #FFF;
    .col-lg-3 {
        display: flex;
    }
    .our-properties__link {
        width: 100%;
        position: relative;
        display: block;
        min-height: 130px;
        margin-bottom: 40px;
        padding: 35px 25px;
        background: $mid_grey;
        border-bottom: 8px solid #FFF;
        text-align: center;
        text-decoration: none;
        line-height: 1.2;
        color: #FFF;
        font-weight: 700;
        @include font-size(16);
        text-transform: uppercase;
        &:after {
            position: absolute;
            bottom: -20px;
            left: 0;
            display: block;
            content: "";
            width: 100%;
            height: 10px;
            background: $grey; 
        }
        span {
            display: none;
            line-height: 150%;
        }
        &.single {
            line-height: 200%;
        }
        &:hover {
            color: $red;
            &, &:after { 
                background: $red;
            }
            span {
                position: absolute;
                top: 40px;
                left: 15%;
                display: block;
                width: 70%;
                padding: 8px 35px 8px 15px;
                color: #FFF;  
                text-align: center;
                font-weight: 400;
                vertical-align: middle;
                @include font-size(12);
                img {
                    position: absolute;
                    top: 12px;
                    right: 15px;
                    height: 20px;
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.who-we-are {
    position: relative;
    .who-we-are__hero {
        position: absolute;
        width: 100%;
        top: 110px;
        color: #FFF;
        h1 {
            line-height: 90%;
            @include font-size(58);
            text-transform: uppercase;
        }
        p {
            max-width: 450px;
            margin: 25px 0 35px;
            line-height: 120%;
            font-weight: 300;
            @include font-size(14);
        }
        @media screen and (max-width: 1200px) {
            .wrapper { 
                width: calc(100% - 80px);
            }
        }
        @media screen and (max-width: 1000px) {
            top: 40px;
            .wrapper { 
                width: 100%;
            }
            h1 {
                @include font-size(26);
            }
            p {
                max-width: 450px;
                margin: 15px 0 25px;
                line-height: 110%;
                @include font-size(13);
            }
        }
    }
    .modal-content {
        margin-top: 100px;
        .close {
            position: absolute;
            top: 0;
            z-index: 500;
            right: 5px;
            color: #CF0A2C;
        } 
        .who-we-are__content {
            position: relative;
            background: #FFF;
            padding: 25px 50px 50px;
            h2 {
                margin-bottom: 35px;
                color: $red;
                @include font-size(28);
            }
            .who-we-are__content--cols {
                position: relative;
                column-count: 2;
                column-gap: 4em;        
            }
            @media screen and (max-width: 1000px) {
                h2 {
                    margin-bottom: 25px;
                    @include font-size(24);
                }
                .who-we-are__content--cols {
                    column-count: auto;
                    .close {
                        top: -65px;
                    }
                }
            }
            @media screen and (max-width: 500px) {
                padding: 15px 20px;
            }
        }
    }
}

.clients {
    .bx-wrapper {
        .bx-viewport {
            background: transparent;
        }
        .bx-controls-direction a {
            margin-top: -25px;
            left: -45px;
            &.bx-next {
                left: auto;
                right: -45px;
            }
            @media screen and (max-width: 1200px) {
               left: -15px;
               &.bx-next {
                    left: auto;
                    right: -15px;
                }
            }
        }
        @media screen and (max-width: 1200px) {
            padding: 0 30px;
        }
    }
    
    .client {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 12.666vw;
        max-height: 148px;
        margin-bottom: 30px;
        background: #FFF;
        & > * {
            text-align: center;
            width: 80%;
        }
        img {
            width: 80%;
            max-width: 110px;
            max-height: 110px;
        }
        @media screen and (max-width: 800px) {
            height: 180px;
            max-height: 180px;
        }
    }
    .arrow-button {
        margin: 20px 0 50px 0;
    }
}