.our-team {
    .intro {
        max-width: 680px;
        margin-bottom: 50px;
        line-height: 110%;
        font-weight: 300;
        @include font-size(18);
        text-transform: uppercase;
    }
    h3 {
        margin: 0 0 30px;
        font-weight: 400;
        @include font-size(16);
        text-transform: uppercase;
    }    
}

.our-team__person {
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 1px solid $red;
    min-height: 190px;
    img { 
        margin-bottom: 25px;
    }
    h4 {
        color: $red;
        font-weight: 400;
        @include font-size(14);
    }
    h5 {
        margin-bottom: 25px;
        @include font-size(11);
    }
    .contacts {
        margin-bottom: 15px;
        a {
            display: block;
            color: $grey;
            @include font-size(10);
        }
    }
    .arrow-button {
        display: inline-block;
        width: auto;
        min-width: 100px;
        margin-right: 10px;
        padding: 8px 30px 8px 10px;
        @include font-size(10);
        img {
            height: 12px;
            top: 13px;
            right: 12px;
        }
        &.vcard img {
            transform: rotate(-90deg);
            top: 15px;
        }
    }
}

.popup.person {
    .our-team__person {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
        .arrow-button {
            margin-top: 25px;
            border: 1px solid $red;
        }
    }
}