.property__images {
    overflow: hidden;
    max-height: 600px;
    padding: 0;
    .home-carousel {
        min-height: 600px;
        background: #FFF;
    }
    .slide {
        &, img {
            max-height: 600px;
            @media screen and (max-width: 800px) {
                max-height: 220px;    
            }
        }
        img {
            width: 100%;
            object-fit: cover;
        }        
    }
    .bx-wrapper .bx-pager {
        padding-left: 50px;
    }
}

.property__nav--surround {
    display: flex;
    align-items: center;
    .property__nav {
        width: 100%;
        padding-right: 30px;
        li {
            border-bottom: 1px solid #FFF;
            &:last-child {
                border-bottom: none;
            }
        }
        a {
            display: block;
            text-align: center;
            color: #FFF;
            padding: 20px 0;
        }
        @media screen and (max-width: 800px) {
            padding-right: 0;
            a {
                padding: 10px 0;
            }
        }
    }
}

.property__info {
    hr {
        margin: 30px 0 25px 0;
    }
    h1 {
        @include font-size(24);
        color: $red;
        span {
            color: $grey;
        }
    }
    h2 {
        border-top: none;
        margin: 25px 0 10px;
    }
    table {
        width: 100%;
        th, td {
            padding: 5px 8px;
        }
        tr {
            border-bottom: 1px solid #FFF;
        }
        thead {
            th {
                background: $red;
                font-weight: 400;
                color: #FFF;
            }
        }
        tbody tr {
            &:nth-child(odd) {
                background: #EEE;
            }
            &:nth-child(even) {
                background: $pink;
            }
        }
        tfoot {
            td {
                background: $grey;
                color: #FFF;
            }
        }
        @media screen and (max-width: 600px) {
            td, th {
                @include font-size(8);
            }
        }
    }
    #property__map {
        margin-top: 25px;
        height: 400px;
        background: #EEE;
    }
}

.scheme__siteplan {
    .row {
        margin-right: 0;
        & > div:last-child {
            background: #F5F5F5;
        }
    }
    .scheme__siteplan--tenancy {
        padding: 20px 5px;
        max-height: 540px;
        overflow: auto;
        .nav-tabs {
            border-bottom: 0;
            margin-bottom: 20px;
            li {
                width: 50%;
            }
            a {
                display: block;
                padding: 10px 35px;
                background: $grey;
                color: #FFF;
                text-align: center;
                text-decoration: none;
                border-radius: 0;
                border: none;                
                &.active {
                    background: $red;
                }
            }
        }
        table {
            width: 100%;
            border-spacing: 1px;
            border-collapse: separate;
            th, td {
                padding: 10px 15px;
                @include font-size(9);
                background: #FFF;
                color: $grey;
                a {
                    color: $red;
                }
            }
            th {
                background: $mid_grey;
                color: #FFF;
                font-weight: 400;
            }
            tr:hover td, tr.warm td {
                background: $pink;
            }
        }
    }
}

.property__contacts {
    margin-top: 50px;
    span {
        display: inline-block;
        width: 20px;
    }
    a {
        color: $grey;
    }
}