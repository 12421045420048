.our-clients {
    .nav-tabs {
        border-bottom: 0;
        margin: 35px 0 25px;
        li {
            margin-bottom: 0;
            &:first-child a {
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
            }
            &:last-child a {
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
            }
        }
        a {
            padding: 10px 35px;
            border: none;
            border-radius: 0;
            background: #FFF;
            color: $grey;
            &.active {
                background: lighten($grey, 10%);
                color: #FFF;
            }
        }
    }
    .client a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}

.popup.company {
    img {
        width: 250px;
        margin-bottom: 25px;
    }
    a {
        display: block;
        font-weight: 700;
        text-decoration: none;
    }
    .biography strong {
        color: $red;
    }
}