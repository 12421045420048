.panel.map {
    padding: 0;
    height: 500px;
    #map {
        height: 500px;
    }
}

.wrapper.visit-us {
    max-width: 730px;
    & > p {
        @include font-size(14);
        line-height: 120%;
        margin-bottom: 2em;
    }
    .visit-us__address {
        position: relative;
        & > img {
            width: 230px;
            margin-bottom: 10px;
        }
        p {
            @include font-size(12);
            line-height: 120%;
            &:last-of-type{
                margin-bottom:75px;
            }
        }
        strong span {
            color: $red;
        }
        .arrow-button {
            position: absolute;
            top: 0;
            right: 0;
            border: 1px solid $grey;
        }
    }
}